import React from 'react';
import './styles.scss';
import GenAIIcon from '../../assets/icons/GenAI';

export default function BoutiqAIButton({ openBoutiqAI, text }) {
    return (
        <div className='boutiq-ai-link-container' onClick={openBoutiqAI}>
            <GenAIIcon color='main'/>
            {text}
        </div>
    )
}