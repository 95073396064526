import React from 'react';
import { useHistory } from 'react-router-dom';
import VolumeUpIcon from '../../../assets/icons/VolumeUp';
import VolumeOffIcon from '../../../assets/icons/VolumeOff';
import { FormattedMessage, useIntl } from 'react-intl';
import { useIntroPage } from '../IntroPageProvider';
import { useClientConfigProvider } from '../../../components/ClientConfigProvider';
import { useInterWindowMessageProvider, REQ_HEADER_TYPES } from '../../../components/InterWindowMessageProvider';
import BoutiqAIButton from '../../../components/boutiqAIButton';
import IntroContent from '../introContent/introContent';
import IntroHeader from '../introHeader/introHeader';
import { callPreviewMessages } from './messages';
import { PAGES } from '../../../constants/routes';
import { FilledButton, OutlinedButton } from '../../../components/boutiq-buttons/BoutiqButton';
import './index.scss';
import {INTRO_PAGES} from "../intro-page";

export default function UserActivityButtons() {
    const history = useHistory();
    const intl = useIntl();
    const { postMessage } = useInterWindowMessageProvider();
    const {
        isMuted,
        setIsMuted,
        isChatEnabled,
        playList,
        config,
    } = useIntroPage();

    const {
        allowOutgoingCalls,
        disableScheduling,
        isStandaloneApp,
        dynamicConfig,
    } = useClientConfigProvider();

    const soundControl = () => {
        setIsMuted(!isMuted);
    }

    const navigateToLobby = () => {
        history.push(PAGES.VIDEO_CALL);
    }

    const onSchedule = () => {
        history.push(PAGES.SCHEDULING);
    }

    const openChat = () => {
        history.push(PAGES.CHAT);
    };

    const openBoutiqAI = () => {
        postMessage(REQ_HEADER_TYPES.OPEN_BOUTIQ_AI, { source: 'boutiq_client_app'});
    };

    const btnType = allowOutgoingCalls && !disableScheduling;

    const scheduleButtonMarkup = (allowOutgoingCalls && !disableScheduling
        ? <OutlinedButton onClick={onSchedule} className='schedule'>
            <FormattedMessage {...callPreviewMessages.cta_schedule} />
        </OutlinedButton>
        : <FilledButton onClick={onSchedule} className='schedule'>
            <FormattedMessage {...callPreviewMessages.cta_schedule} />
        </FilledButton>)

    return (
        <div className={`intro-main-layout-wrapper${isStandaloneApp ? ' full-screen' : ''}`}>
            <IntroHeader history={history} />
            <IntroContent />
            <div className='intro-main-layout'>
                <div className='main-buttons-wrapper'>
                    <div className='main-buttons'>
                        {allowOutgoingCalls &&
                            <FilledButton onClick={navigateToLobby} className={'connect'}>
                                <FormattedMessage {...callPreviewMessages.connect_now} />
                            </FilledButton>}
                        {!disableScheduling && scheduleButtonMarkup}
                    </div>
                    <div className={`chat-link-container${history.location.pathname === `${PAGES.INTRO}/${INTRO_PAGES.INTRO_WELCOME}` ? ' contrast' : ''}`}>
                        {isChatEnabled &&
                            <span onClick={openChat}>
                                <FormattedMessage {...callPreviewMessages.chat} />
                            </span>
                        }                        
                    </div>
                    {config?.showBoutiqAIButton && <BoutiqAIButton openBoutiqAI={openBoutiqAI} text={intl.formatMessage(callPreviewMessages.openBoutiqAI, { serviceName: dynamicConfig.smartAgentServiceName })} />}
                </div>
                {playList.length > 0 && history.location.pathname === PAGES.INTRO ? <button className='sound-control-container'>
                    <div className='sound-control' onClick={soundControl}>
                        {isMuted ?
                            <VolumeOffIcon size={24} className='unmuted' /> :
                            <VolumeUpIcon size={24} className='unmuted' />}
                    </div>
                </button> : null}
            </div>
        </div>
    )
}
